<template>
  <div class="thirdparty-container">
    <img src="@/assets/fd-loading.png" class="loading-img" loading="lazy"/>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { removeToken } from '@/libs/utils/auth'
export default {
  data()  {
    return {}
  },
  mounted() {
    if(this.$route.query && this.$route.query.log) {
      this.LoginWithToken(this.$route.query.log).then( _ => {
        this.GetUserInfo(this.$route.query.log).then(res => {
          if(res.errorcode == 0) {
            this.$router.push({name:'Home'});
          }else if(res.errorcode >= 100000){
            removeToken();
            this.$router.replace('/login');
          }else {
            console.error(JSON.stringify(res))
          }
        }).catch( _ => {
          removeToken();
          this.$router.replace('/login');
        })
        
      });
    }
  },
  methods: {
    ...mapActions(['LoginWithToken','GetUserInfo'])
  }
}
</script>

<style lang="less" scoped>
  .thirdparty-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .loading-img {
    width: 140px;
    height: 140px;
    object-fit: contain;
  }
</style>